import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useEffect, useContext, useState, useRef, lazy, Suspense } from "react";

// import Modal from "./Components/Other/ModalProvider/Modal";
import ParticlesBg from "./Components/Other/Styles&Animations/ParticlesBg";
import Kontakt from "./Components/Browser/Contact/Kontakt";
import Main from "./Components/Browser/Main/Main";
import Me from "./Components/Browser/Me/Me";
import Privacy from "./Components/Browser/Privacy/Privacy";
import NavBar from "./Components/Browser/Interface/NavBar";
import Animation from "./Components/Animation";
import { LanguageContext } from "./Components/Other/Language/LanguageProvider";
import * as lang from "./Components/Other/Language/Language";
import Settings from "./Components/Browser/Interface/Settings";
import EfficiencyContext from "./Components/Other/Efficiency/EfficiencyContext";
import { CircularProgress } from "@mui/material";
import CookiesContainer from "./Components/Other/Cookies/Cookies";
import useModal from "./Components/Browser/Interface/Modal/useModal";
import HelpIcon from "@mui/icons-material/Help";
import WarningIcon from "@mui/icons-material/Warning";

const CanvasPage = lazy(
  () => import("./Components/Browser/Portfolio/CanvasPage")
);

function App() {
  const { constent, OffModal, OnModal, Modal } = useModal();
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { level } = useContext(EfficiencyContext);

  const location = useLocation();
  const cookies = !localStorage.getItem("Cookies");
  // eslint-disable-next-line
  const [cookiesActive, setCookiesActive] = useState(cookies);
  const timeDelay = useRef<number>(0);
  const lastDeltaY = useRef<number>(0);
  function SetCookiesActive(params: boolean) {
    setCookiesActive(params);
  }

  // ! touch change
  useEffect(() => {
    let xDown: number | null;
    let yDown: number | null;

    //*get cords of touch start
    function handleTouchStart(evt: any) {
      if (evt.touches.length === 2) {
        return;
        // kod do obsługi dwóch dotknięć palców
      }
      xDown = evt.touches[0].clientX;
      yDown = evt.touches[0].clientY;
    }

    //*get vector of touch move
    function handleTouchMove(evt: any) {
      // alert("zaczeło sie");
      if (evt.touches.length === 2) {
        return;
        // kod do obsługi dwóch dotknięć palców
      }

      let type: number = lastDeltaY.current < 60 ? 200 : 50;
      let status: boolean = false;
      let time: number = Date.now();

      if (time - timeDelay.current > type) {
        timeDelay.current = Date.now();
        status = true;
      } else {
        timeDelay.current = Date.now();
        status = false;
      }

      if (!status) {
      } else {
        const excludedElement = document.querySelector(".excluded-element");
        if (excludedElement && excludedElement.contains(evt.target as Node)) {
          return;
        }
        // prevent event if one of the variables are incorrect
        if (!xDown || !yDown) {
          return;
        }
        //get cords of toutch
        const xDiff: number = xDown - evt.touches[0].clientX;
        const yDiff: number = yDown - evt.touches[0].clientY;
        //condition that check is toutch move isn't perfect
        if (Math.abs(yDiff) > Math.abs(xDiff)) {
          if (yDiff < 0) {
            //if you touch DOWN you change the route and move to the given page

            switch (location.pathname) {
              case "/":
                navigate("/contact");
                break;
              case "/contact":
                navigate("/portfolio");
                break;
              case "/me":
                navigate("/");
                break;
              default:
                break;
            }
          } else {
            //if you touch UP you change the route and move to the given page

            switch (location.pathname) {
              case "/":
                navigate("/me");
                break;
              case "/contact":
                navigate("/");
                break;
              case "/me":
                navigate("/portfolio");
                break;
              default:
                break;
            }
          }
        }
        // reset value at the end
        xDown = null;
        yDown = null;
      }
    }
    const routesDiv = document.querySelector(".routesDiv");

    routesDiv &&
      routesDiv.addEventListener("touchstart", handleTouchStart, {
        passive: true,
      });
    routesDiv &&
      routesDiv.addEventListener("touchmove", handleTouchMove, {
        passive: true,
      });
    return () => {
      routesDiv &&
        routesDiv.removeEventListener("touchstart", handleTouchStart);
      routesDiv && routesDiv.removeEventListener("touchmove", handleTouchMove);
    };
    // eslint-disable-next-line
  }, [location]);

  // ! change with scroll/touchpad
  useEffect(() => {
    function handleScroll(e: WheelEvent) {
      let type: number = lastDeltaY.current < 60 ? 300 : 50;
      let status: boolean = false;
      let time: number = Date.now();

      if (time - timeDelay.current > type) {
        timeDelay.current = Date.now();
        status = true;
      } else {
        timeDelay.current = Date.now();
        status = false;
      }

      if (!status) {
      } else {
        const excludedElement = document.querySelector(".excluded-element");
        if (location.pathname === "/" && excludedElement) {
          return;
          // console.log(excludedElement);
        }

        if (e.deltaY > 0) {
          //if you touch DOWN you change the route and move to the given page
          switch (location.pathname) {
            case "/":
              navigate("/contact", { replace: false });
              break;
            case "/me":
              navigate("/", { replace: false });
              break;
            case "/contact":
              navigate("/portfolio", { replace: false });
              break;
            default:
              break;
          }
        } else {
          //if you touch UP you change the route and move to the given page
          switch (location.pathname) {
            case "/":
              navigate("/me");
              break;
            case "/contact":
              navigate("/");
              break;
            case "/me":
              navigate("/portfolio");
              break;
            default:
              break;
          }
        }
      }
    }
    window.addEventListener("wheel", handleScroll);
    return () => window.removeEventListener("wheel", handleScroll);

    // eslint-disable-next-line
  }, [location]);

  // ! change with the keys
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      let type: number = lastDeltaY.current < 60 ? 300 : 50;
      let status: boolean = false;
      let time: number = Date.now();

      if (time - timeDelay.current > type) {
        timeDelay.current = Date.now();
        status = true;
      } else {
        timeDelay.current = Date.now();
        status = false;
      }

      if (!status) {
      } else {
        const keyCode = event.code;

        if (keyCode === "ArrowDown" || keyCode === "PageDown") {
          //if you touch DOWN you change the route and move to the given page
          switch (location.pathname) {
            case "/":
              navigate("/contact", { replace: false });
              break;
            case "/me":
              navigate("/", { replace: false });
              break;
            case "/contact":
              navigate("/portfolio", { replace: false });
              break;
            default:
              break;
          }
        }
        if (keyCode === "ArrowUp" || keyCode === "PageUp") {
          //if you touch UP you change the route and move to the given page
          switch (location.pathname) {
            case "/":
              navigate("/me");
              break;
            case "/contact":
              navigate("/");
              break;
            case "/me":
              navigate("/portfolio");
              break;
            default:
              break;
          }
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!localStorage.getItem("Tutorial")) {
      OnModal({
        icon: HelpIcon,
        title: "Tutorial",
        text: lang.ModalTutorial[language],
        buttonText: "Zamknij",
        type: "Tutorial",
        delay: 2,
      });
    }
  }, []);
  useEffect(() => {
    if (location.pathname === "/portfolio") {
      if (localStorage.getItem("Canvas") === null) {
        OnModal({
          icon: WarningIcon,
          title: "Uwaga",
          text: lang.ModalCanvas[language],
          buttonText: "Zamknij",
          type: "Canvas",
          delay: 1,
        });
      }
    }
  }, [location]);

  return (
    <div className="App flex">
      {/* old dark:bgPlaster bgPlaster-white */}
      <div className="dark:bgPlaster bgPlaster-white absolute h-[100dvh] w-screen bg-white bg-cover bg-no-repeat" />
      {level !== 1 && location.pathname !== "/portfolio" && <ParticlesBg />}

      <Settings />

      <NavBar />
      <AnimatePresence>{constent.on && <Modal />}</AnimatePresence>

      <AnimatePresence>
        {!localStorage.getItem("Cookies") && (
          <CookiesContainer CookiesActive={SetCookiesActive} />
        )}
      </AnimatePresence>

      {/* //! Router */}
      <div className="routesDiv !h-full">
        <AnimatePresence>
          <Routes key={location.pathname} location={location}>
            <Route path="/" element={<Main />} />
            <Route path="/me" element={<Me />} />
            <Route
              path="/portfolio"
              element={
                <Suspense
                  fallback={
                    <div className="absolute z-[999999] flex h-screen w-screen items-center justify-center text-5xl text-white">
                      <CircularProgress color="primary" />
                    </div>
                  }
                >
                  <CanvasPage />
                </Suspense>
              }
            />
            <Route path="/contact" element={<Kontakt />} />
            <Route path="/animation" element={<Animation />} />
            <Route path="/privacy-policy" element={<Privacy />} />
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default App;
