import BatteryCharging20Icon from "@mui/icons-material/BatteryCharging20";
import BatteryCharging60Icon from "@mui/icons-material/BatteryCharging60";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import Button from "./Button";
import { EfficiencyContext } from "../../../Other/Efficiency/EfficiencyContext";
import { Tooltip } from "@mui/material";
import { LanguageContext } from "../../../Other/Language/LanguageProvider";
import { TooltipEffency } from "../../../Other/Language/Language";
import { useContext } from "react";

const EfficiencyButton = () => {
  const { level, changeLevel } = useContext(EfficiencyContext);
  const { language } = useContext(LanguageContext);

  function changeEff() {
    switch (level) {
      case 1:
        changeLevel(2);
        break;
      case 2:
        changeLevel(3);
        break;
      case 3:
        changeLevel(1);
        break;
      default:
        changeLevel(2);
    }
  }

  return (
    <Button onClick={changeEff}>
      {level === 1 && (
        <Tooltip title={TooltipEffency[language][0]}>
          <BatteryCharging20Icon />
        </Tooltip>
      )}
      {level === 2 && (
        <Tooltip title={TooltipEffency[language][1]}>
          <BatteryCharging60Icon />
        </Tooltip>
      )}
      {level === 3 && (
        <Tooltip title={TooltipEffency[language][2]}>
          <BatteryChargingFullIcon />
        </Tooltip>
      )}
    </Button>
  );
};

export default EfficiencyButton;
