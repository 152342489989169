import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  animateXStart,
  initRot_90,
} from "../../Other/Styles&Animations/MotionObj";
import { LanguageContext } from "../../Other/Language/LanguageProvider";
import * as lang from "../../Other/Language/Language";
import InfoIcon from "@mui/icons-material/Info";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HomeIcon from "@mui/icons-material/Home";

function NavBar() {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const location = useLocation();
  const navbarRef = useRef<HTMLDivElement>(null);
  const mainBtn = useRef<HTMLDivElement>(null);
  const secondBtn = useRef<HTMLDivElement>(null);
  const thirdBtn = useRef<HTMLDivElement>(null);
  const fourBtn = useRef<HTMLDivElement>(null);
  const dot = useRef<HTMLDivElement>(null);
  const [currentElement, setCurrentElement] = useState<number>(0);
  const [positionOfDot, setpositionOfDot] = useState(0);

  //! positions of all elements needed to set

  function getRect(
    element: HTMLButtonElement | HTMLAnchorElement | HTMLDivElement
  ) {
    const elementHeight = Number(element?.getBoundingClientRect().height) / 2;
    const dotHeight = Number(dot.current?.getBoundingClientRect().height) / 2;
    const navbarWidth = Number(
      navbarRef.current?.getBoundingClientRect().width
    );
    setpositionOfDot(navbarWidth - dotHeight);
    const final =
      Number(element?.getBoundingClientRect().top) + elementHeight - dotHeight;
    setCurrentElement(final);
  }

  //! update dot position after change path
  function updateChosenElem() {
    switch (location.pathname) {
      case "/":
        if (mainBtn.current) getRect(mainBtn.current);
        break;
      case "/me":
        if (secondBtn.current) getRect(secondBtn.current);
        break;
      case "/portfolio":
        if (thirdBtn.current) getRect(thirdBtn.current);
        break;

      case "/contact":
        if (fourBtn.current) getRect(fourBtn.current);
        break;

      default:
        console.error("No matching pathname");
        break;
    }
  }

  //! wait for updete chossen nav element
  useEffect(() => {
    updateChosenElem();
    // eslint-disable-next-line
  }, [location]);

  //! add eventlisteners on resize
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      updateChosenElem();
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, [windowSize]);

  return (
    <div
      className={`relative left-0 !top-0 z-50 flex h-[100dvh] w-12 min-w-[3rem] !max-w-lg items-center bg-white bg-opacity-0 dark:bg-black
       dark:bg-opacity-0  sm:w-16 sm:min-w-[4rem] xl:min-w-[5rem] 2xl:min-w-[5rem] 3xl:min-w-[6.8rem] 5xl:min-w-[8rem] ${
         location.pathname === "/portfolio"
           ? "!bg-opacity-50 dark:!bg-opacity-50"
           : ""
       }`}
    >
      {location.pathname !== "/privacy-policy" && (
        <motion.div
          ref={dot}
          className="!absolute top-0 -left-0.5 z-50 h-3 w-3 rounded-full bg-neutral-700 dark:bg-white sm:-left-[1.5px] sm:h-4 sm:w-4"
          initial={{}}
          //! set dot position to currently chossen element and set letf margin
          animate={{ y: currentElement, x: positionOfDot }}
        />
      )}

      {/* //! Div needed as a child to be able to position it*/}
      <div
        ref={navbarRef}
        className="z-50 flex h-128 max-h-[100svh]  flex-col border-r-2 !border-neutral-700 dark:!border-white sm:min-w-[4rem] sm:border-r-[3px] lg:h-3/4 xl:min-w-[5rem] 2xl:min-w-[5rem] 3xl:min-w-[6.8rem] 5xl:min-w-[8rem]"
      >
        {/* //! Contact*/}
        <motion.div
          initial={initRot_90}
          animate={animateXStart}
          transition={{ delay: 0.4 }}
          className="NavbarButton"
          ref={fourBtn}
        >
          {/*//! Ikona/przycisk do przejscia na kontakty */}
          <motion.div
            // ref={fourBtn2}
            initial={{ x: -400, opacity: 0, rotate: "90deg" }}
            animate={animateXStart}
            className={
              "noSelect flex h-full items-center justify-center xl:hidden tall:xl:flex"
            }
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              navigate("/contact");
            }}
          >
            <ContactPageIcon className="NavbarButtonIcon" />
          </motion.div>
          {/*//! Link/text do przejscia na KONTAKTY */}
          <Link to={"contact"} className="NavbarButtonLink">
            {lang.navBar4[language]}
          </Link>
        </motion.div>
        {/* //! Portfolio */}
        <motion.div
          initial={initRot_90}
          animate={animateXStart}
          transition={{ delay: 0.6 }}
          ref={thirdBtn}
          className="NavbarButton"
        >
          <motion.div
            initial={{ x: -400, opacity: 0, rotate: "90deg" }}
            animate={animateXStart}
            className={
              "noSelect flex h-full items-center justify-center xl:hidden tall:xl:flex "
            }
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              navigate("/portfolio");
            }}
          >
            <RocketLaunchIcon className="NavbarButtonIcon" />
          </motion.div>
          <Link to={"portfolio"} className="NavbarButtonLink">
            {lang.navBar3[language]}
          </Link>
        </motion.div>

        {/* //! About me*/}
        <motion.div
          initial={initRot_90}
          animate={animateXStart}
          transition={{ delay: 0.8 }}
          className="NavbarButton"
          ref={secondBtn}
        >
          <motion.div
            initial={{ x: -400, opacity: 0, rotate: "90deg" }}
            animate={animateXStart}
            className={
              "noSelect flex h-full items-center justify-center xl:hidden tall:xl:flex"
            }
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              navigate("/me");
            }}
          >
            <InfoIcon className="NavbarButtonIcon" />
          </motion.div>

          <Link to={"/me"} className="NavbarButtonLink">
            {lang.navBar2[language]}
          </Link>
        </motion.div>

        {/* //! Home */}
        <motion.div
          initial={initRot_90}
          animate={animateXStart}
          transition={{ delay: 1 }}
          className={"NavbarButton !mt-auto"}
          ref={mainBtn}
        >
          <motion.div
            initial={{ x: -400, opacity: 0, rotate: "90deg" }}
            animate={animateXStart}
            className={
              "noSelect mt-auto flex h-full items-center justify-center xl:hidden tall:xl:flex "
            }
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              navigate("/");
            }}
          >
            <HomeIcon className="NavbarButtonIcon" />
          </motion.div>

          <Link to={"/"} className="NavbarButtonLink">
            {lang.navBar1[language]}
          </Link>
        </motion.div>
      </div>
    </div>
  );
}

export default NavBar;
