import { useFullscreen } from "@mantine/hooks";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Button from "./Button";
import { Tooltip } from "@mui/material";
import { useContext } from "react";
import { LanguageContext } from "../../../Other/Language/LanguageProvider";
import { TooltipFullscreen } from "../../../Other/Language/Language";

export default function Fullscreen() {
  const { language } = useContext(LanguageContext);
  const { toggle, fullscreen } = useFullscreen();

  return (
    <Button onClick={toggle}>
      <Tooltip
        title={
          fullscreen
            ? TooltipFullscreen[language][0]
            : TooltipFullscreen[language][1]
        }
      >
        {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </Tooltip>
    </Button>
  );
}
