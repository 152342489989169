import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import LanguageProvider from "./Components/Other/Language/LanguageProvider";
import { EfficiencyProvider } from "./Components/Other/Efficiency/EfficiencyContext";
import { ThemeProvider, createTheme } from "@mui/material";
import { AnimatePresence } from "framer-motion";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "rgb(79 70 229)",
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HashRouter>
    <React.StrictMode>
      <EfficiencyProvider>
        <LanguageProvider>
          <AnimatePresence>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </AnimatePresence>
        </LanguageProvider>
      </EfficiencyProvider>
    </React.StrictMode>
  </HashRouter>
);
