import React, { useState, createContext, useEffect } from "react";

type LanguageContextType = {
  level: number;
  changeLevel: (newLanguage: number) => void;
};

type Props = {
  children: React.ReactNode;
};
export const EfficiencyContext = createContext<LanguageContextType>(
  // * add default object (if nothing will be get from props)
  {
    level: 2,
    changeLevel: () => {},
  }
);

export const EfficiencyProvider = ({ children }: Props) => {
  const [level, setLevel] = useState<number>(2);

  useEffect(() => {
    localStorage.getItem("Efficiency") &&
      setLevel(Number(localStorage.getItem("Efficiency")));
  }, []);

  function changeLevel(num: number) {
    localStorage.setItem("Efficiency", String(num));
    setLevel(num);
  }

  return (
    <EfficiencyContext.Provider value={{ level, changeLevel }}>
      {children}
    </EfficiencyContext.Provider>
  );
};

export default EfficiencyContext;
