import MainButtons from "./MainButtons";
import { LanguageContext } from "../../Other/Language/LanguageProvider";
import { secondMainText } from "../../Other/Language/Language";
import { useContext } from "react";

const SecondMainText = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div className=" z-40 w-3/4  border-t-2 border-t-mainDark pt-[5vh] dark:border-t-white dark:text-white sm:border-t-[3px]">
      <p
        className={
          "mb-[4vh] whitespace-pre text-center  font-consolas text-tmd uppercase text-mainDark dark:text-white tall:text-left md:text-center xl:text-txl"
        }
      >
        {secondMainText[language]}
      </p>
      <MainButtons />
    </div>
  );
};

export default SecondMainText;
