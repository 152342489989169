export const textSizeBig: string =
  " text-xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-4xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-8xl";
export const textSize: string =
  " text-lg sm:text-xl md:text-xl lg:text-xl xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-7xl";
export const textSizeSmall: string =
  " text-[0.3rem] sm:text-xs md:text-xs lg:text-xs xl:text-xs 2xl:text-xs 3xl:text-base 4xl:text-base 4xl:font-bold 5xl:text-lg 6xl:text-2xl";
export const textSizeXs: string =
  " text-xs sm:text-xs md:text-sm lg:text-sm xl:text-base 2xl:text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-5xl";

export const initDownStart: object = {
  y: -400,
  opacity: 0,
};
export const initUpStart: object = {
  y: 400,
  opacity: 0,
};
export const animateYStart: object = {
  y: 0,
  opacity: 1,
};
export const animateXStart: object = {
  x: 0,
  opacity: 1,
};
export const animateXend: object = {
  x: 400,
  opacity: 0,
};
export const exitYUp: object = {
  opacity: 0,
  y: -400,
  transition: { delay: 0 },
};
export const exitYDown: object = {
  opacity: 0,
  y: 400,
  transition: { delay: 0 },
};
export const tranD0: object = {
  delay: 0,
};
export const tranDu_0: object = {
  duration: 0,
};
export const tranDu_5: object = {
  duration: 0.5,
};
export const tranDu_75: object = {
  duration: 0.75,
};
export const initRot_90: object = {
  x: -300,
  rotate: "-90deg",
  opacity: 0,
};

export const Variant5 = {
  startFirst: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
  infinitFirst: {
    rotate: [0, 5, 0, -5, 0],
    transition: {
      duration: 0.2,
      delay: 2,
      repeatDelay: 4,
      repeat: Infinity,
    },
  },
};
export const animation1: object = {
  scale: [1, 1, 1.1, 1.1, 1.1, 1, 1],
  rotate: [0, 0, 5, 0, -5, 0, 0],
};

export const animation2: object = {
  scale: [1.1, 1.1],
  rotate: [-4, 4],
};
export const transition1: object = {
  times: [0, 0.9, 0.95, 1, 1.05, 1.1, 2],
  duration: 2,
  repeat: Infinity,
  repeatType: "reverse",
};
export const transition2: object = {
  duration: 0.1,
  repeat: Infinity,
  repeatType: "reverse",
};
