import { useContext, useEffect, useState } from "react";
import Fullscreen from "./Buttons/FullscreenButton";
import { AnimatePresence, motion } from "framer-motion";
import SettingsIcon from "@mui/icons-material/Settings";
import Button from "./Buttons/Button";
import ThemeButton from "./Buttons/ThemeButton";
import LanguageButton from "./Buttons/LanguageButton";
import { LanguageContext } from "../../Other/Language/LanguageProvider";
import EfficiencyButton from "./Buttons/EfficiencyButton";
import { Tooltip } from "@mui/material";
import { TooltipOptions } from "../../Other/Language/Language";

const Settings = () => {
  const [active, setActive] = useState(false);
  const [theme, setTheme] = useState(false);
  const { language, changeLanguage } = useContext(LanguageContext);

  // ! we check it here so that the data is loaded even when the button components are not loaded
  useEffect(() => {
    // ! here we check the initial state of the Language context and load the data into a variable that is passed to the component
    if (localStorage.getItem("language")) {
      changeLanguage(String(localStorage.getItem("language")));
    }
    // ! here we check the initial state of the theme and load the data into a variable that is passed to the component
    if (localStorage.getItem("theme")) {
      document
        .querySelector("html")
        ?.setAttribute("data-mode", String(localStorage.getItem("theme")));
      localStorage.getItem("theme") === "light"
        ? setTheme(true)
        : setTheme(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <motion.div
      initial={{ x: 200 }}
      animate={{ x: 0 }}
      transition={{ delay: 1.4 }}
      className="fixed top-0 right-2 z-50 inline-flex h-20 min-w-fit items-center gap-3 sm:top-0 sm:right-4 sm:gap-4 xl:top-2 xl:right-4 xl:gap-6"
    >
      <AnimatePresence>
        {/*//! if active open */}
        {active && (
          <motion.div
            initial={{ x: "30%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "30%", opacity: 0 }}
            transition={{ duration: 0.5 }}
            onClick={() => setActive(true)}
            onBlur={() => setActive(false)}
            className="-right-12 inline-flex gap-4 "
          >
            {/* //! Eff button */}
            <EfficiencyButton />
            {/* //! Language button */}
            <LanguageButton />
            {/* //! Theme button */}
            <ThemeButton start={theme} />
            {/* //! Fullscreen */}

            <Fullscreen />
          </motion.div>
        )}
      </AnimatePresence>

      <Button
        title="settings"
        onClick={() => setActive((prev) => !prev)}
        onBlur={() => setActive(false)}
        animate={
          active
            ? { rotate: 90, backgroundColor: "rgb(79 70 229)" }
            : { rotate: 0 }
        }
        className=" 
        h-12 max-h-[10vw] w-12 
         max-w-[10vw] origin-center rounded-full
          bg-neutral-700  text-white sm:h-12 sm:w-12 
          xl:h-14 xl:w-14
          "
      >
        <Tooltip
          title={TooltipOptions[language]}
          placement="top"
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 5],
                },
              },
            ],
          }}
        >
          <SettingsIcon />
        </Tooltip>
      </Button>
    </motion.div>
  );
};

export default Settings;
