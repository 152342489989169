import { useContext } from "react";
import { LanguageContext } from "../../../Other/Language/LanguageProvider";
import Button from "./Button";
import { Tooltip } from "@mui/material";
import { TooltipLanguage } from "../../../Other/Language/Language";

const LanguageButton = () => {
  const { language, changeLanguage } = useContext(LanguageContext);

  //! toggle language and save it to localstorage
  function change() {
    localStorage.setItem("language", language === "en" ? "pl" : "en");
    language === "en" ? changeLanguage("pl") : changeLanguage("en");
  }

  return (
    <Button
      onClick={change}
      className="z-40  h-10 max-h-[10vw] w-10 max-w-[10vw] origin-center rounded-full   bg-neutral-700 text-white  dark:bg-neutral-300 dark:text-indigo-500 sm:h-10 sm:w-10  xl:h-12 xl:w-12"
    >
      {language === "en" ? (
        <Tooltip
          title={TooltipLanguage[language]}
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 5],
                },
              },
            ],
          }}
        >
          <img
            alt="English"
            className="m-auto w-1/2 cursor-pointer rounded-md drop-shadow-md"
            src="./Media/Flag_of_the_United_States.svg"
            loading="lazy"
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={TooltipLanguage[language]}
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 5],
                },
              },
            ],
          }}
        >
          <img
            alt="Polski"
            className="m-auto w-1/2 cursor-pointer rounded-md drop-shadow-md"
            src="./Media/Flag_of_Poland.svg"
            loading="lazy"
          />
        </Tooltip>
      )}
    </Button>
  );
};

export default LanguageButton;
