import {
  motion,
  useAnimation,
  AnimationControls,
  HTMLMotionProps,
} from "framer-motion";
import { useState } from "react";

interface Props extends HTMLMotionProps<"button"> {
  startText: string;
  secondText: string;
  textToCopy: string;
}

const AnimatedButton: React.FC<Props> = ({
  startText,
  secondText,
  textToCopy,
  ...props
}) => {
  const [text, setText] = useState<string>(startText);
  const controls: AnimationControls = useAnimation();
  const time = 1;
  const timeOut: number = time * 500;
  const colors = ["rgb(64, 64, 64)", "#6C63FF"];

  function copy() {
    const copyText: string = textToCopy;
    navigator.clipboard.writeText(copyText);
  }

  const handleAnimation = () => {
    controls.start({
      rotateY: ["0deg", "90deg", "0deg"],
      backgroundColor:
        text === startText ? [colors[0], colors[1]] : [colors[1], colors[0]],
      transition: { duration: time },
    });
    setTimeout(() => {
      text === startText ? setText(secondText) : setText(startText);
    }, timeOut);
  };

  return (
    <motion.button
      {...props}
      initial={{ backgroundColor: colors[0] }}
      animate={controls}
      onClick={() => {
        handleAnimation();
        copy();
      }}
      onAnimationComplete={() => {
        setTimeout(() => {
          text === secondText && handleAnimation();
        }, timeOut);
      }}
      className="relative flex h-12 w-1/2 min-w-[6rem]
        items-center justify-center overflow-hidden rounded-full text-white  sm:min-h-[4rem]  
        sm:max-w-[10rem]  sm:tall:min-h-[3rem]     
        sm:tall:max-w-[6rem] 2xl:min-h-[4rem] 
        2xl:max-w-[14rem] 3xl:min-h-[4rem]
        3xl:max-w-[17rem] 4xl:min-h-[5rem] 4xl:max-w-[20rem]"
    >
      {text}
    </motion.button>
  );
};

export default AnimatedButton;
