import { motion } from "framer-motion";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useContext } from "react";
import { LanguageContext } from "../../Other/Language/LanguageProvider";
import * as lang from "../../Other/Language/Language";

const Me = () => {
  const { language } = useContext(LanguageContext);
  sessionStorage.setItem("lastSite", "");

  return (
    <motion.div
      initial={{ y: -1000 }}
      animate={{ y: 0 }}
      exit={{ y: 1000 }}
      transition={{ duration: 0.5 }}
      className="absolute flex h-[100dvh] w-[calc(100%-3rem)] flex-row justify-center tall:justify-center sm:w-[calc(100%-4rem)] xl:w-[calc(100%-5rem)] 2xl:w-[calc(100%-6rem)] 3xl:w-[calc(100%-7rem)] 5xl:w-[calc(100%-8rem)]"
    >
      {/* //!texts */}
      <div className="z-40 flex h-full w-full flex-col items-center justify-evenly tall:max-w-full tall:flex-row  md:text-center xl:flex-row">
        <div className="mt-[10vh] flex h-full w-3/4 max-w-xl flex-col items-center justify-center rounded-lg border-2 border-neutral-700 p-8 px-[3vw] backdrop-blur-[2px] dark:border-white tall:justify-start lg:h-auto xl:ml-10 xl:mt-0 xl:max-w-4xl">
          <div
            className="
            z-40 inline-flex w-full justify-center 
            border-b-2 !border-mainDark  pb-[3vh]
            font-consolas text-t2xl  font-extrabold uppercase text-mainDark
          dark:!border-white dark:text-white tall:!max-w-[40vw] tall:text-tlg
            sm:border-b-[3px] xl:text-t3xl 2xl:text-t4xl"
          >
            {lang.meTitle[language]}
            <motion.div
              className="ml-[1vw] w-1/12"
              initial={{ y: 0 }}
              animate={{ y: -10 }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            >
              <AutoStoriesIcon
                className={
                  "!h-full !w-full fill-mainDark text-mainDark dark:fill-white"
                }
              />
            </motion.div>
          </div>
          <p
            className={
              "excluded-element relative z-40 mt-[3vh] box-border max-h-[25dvh] w-full overflow-y-scroll pl-8 pr-8 text-center font-consolas  text-tsm text-mainDark dark:text-white tall:max-h-[50dvh] tall:!max-w-[40vw]  sm:overflow-auto sm:text-tmd xl:min-h-[40vh] xl:text-tlg 2xl:text-tlg 3xl:text-txl"
            }
          >
            {lang.meText[language]}
          </p>
        </div>

        <div className="my-auto mb-[10vh] grid h-[50svh] min-h-[50svh] w-full max-w-xl grid-cols-2 grid-rows-2 justify-center gap-4 p-8 tall:ml-4 tall:h-[100dvh] tall:gap-1 xl:mb-auto xl:h-[60svh] xl:max-w-3xl">
          <motion.div
            variants={{
              startFirst: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                },
              },
              infinitFirst: {
                rotate: [0, 5, 0, -5, 0],
                transition: {
                  duration: 0.2,
                  delay: 0.5,
                  repeatDelay: 4,
                  repeat: Infinity,
                },
              },
            }}
            animate={["startFirst", "infinitFirst"]}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="max-w-h z-50 m-auto aspect-square max-h-full w-28 max-w-[20vw] cursor-pointer rounded-[6px] bg-neutral-700 shadow-md dark:bg-neutral-700 tall:w-12 sm:w-36 tall:sm:w-24 tall:md:w-28 lg:w-36  tall:lg:w-32 xl:w-40 tall:xl:w-32 2xl:w-48 3xl:w-60"
            onClick={() => window.open("https://github.com/TheLoloS", "_blank")}
          >
            <GitHubIcon className="relative z-30 !h-full !w-full text-white" />
          </motion.div>
          <motion.div
            variants={{
              startFirst: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                },
              },
              infinitFirst: {
                rotate: [0, 5, 0, -5, 0],
                transition: {
                  duration: 0.2,
                  delay: 1,
                  repeatDelay: 4,
                  repeat: Infinity,
                },
              },
            }}
            animate={["startFirst", "infinitFirst"]}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="max-w-h z-50 m-auto aspect-square max-h-full w-28 max-w-[20vw] cursor-pointer rounded-[6px] bg-neutral-700 shadow-md dark:bg-neutral-700 tall:w-12 sm:w-36 tall:sm:w-24 tall:md:w-28 lg:w-36  tall:lg:w-32 xl:w-40 tall:xl:w-32 2xl:w-48 3xl:w-60"
            onClick={() =>
              window.open("https://www.facebook.com/thlspl", "_blank")
            }
          >
            <FacebookIcon className="relative z-30 !h-full !w-full text-white" />
          </motion.div>
          <motion.div
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/piotr-sa%C5%82aga-b3097a263/"
              )
            }
            variants={{
              startFirst: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                },
              },
              infinitFirst: {
                rotate: [0, 5, 0, -5, 0],
                transition: {
                  duration: 0.2,
                  delay: 1.5,
                  repeatDelay: 4,
                  repeat: Infinity,
                },
              },
            }}
            animate={["startFirst", "infinitFirst"]}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="z-50 m-auto aspect-square max-h-full w-28 max-w-[20vw] cursor-pointer rounded-[6px] bg-neutral-700 shadow-md dark:bg-neutral-700 tall:w-12 sm:w-36 tall:sm:w-24 tall:md:w-28 lg:w-36  tall:lg:w-32 xl:w-40 tall:xl:w-32 2xl:w-48 3xl:w-60"
          >
            <LinkedInIcon className="relative z-30 !h-full !w-full text-white" />
          </motion.div>
          <motion.div
            onClick={() => window.open("https://www.youtube.com/@TheLoloS")}
            variants={{
              startFirst: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                },
              },
              infinitFirst: {
                rotate: [0, 5, 0, -5, 0],
                transition: {
                  duration: 0.2,
                  delay: 2,
                  repeatDelay: 4,
                  repeat: Infinity,
                },
              },
            }}
            animate={["startFirst", "infinitFirst"]}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="max-w-h z-50 m-auto aspect-square max-h-full w-28 max-w-[20vw] cursor-pointer rounded-[6px] bg-neutral-700 shadow-md dark:bg-neutral-700 tall:w-12 sm:w-36 tall:sm:w-24 tall:md:w-28 lg:w-36  tall:lg:w-32 xl:w-40 tall:xl:w-32 2xl:w-48 3xl:w-60"
          >
            <YouTubeIcon className="relative z-30 !h-full !w-full text-white" />
          </motion.div>
        </div>
      </div>

      {/* //! image */}
      {/* <img
        className=" absolute bottom-0 right-[-15vw]   z-20 h-[100vh] w-[50vw] opacity-30"
        src="Media/phone.svg"
      /> */}
      {/* <motion.img initial={initUpStart} animate={animateYStart} transition={{ duration: 0.3, delay: 0.5 }} exit={exitYDown} src="Media/undraw_programming_re_kg9v.svg" alt="#" className='absolute bottom-0 ml-[12vw] w-[38vw] z-20 ' /> */}
    </motion.div>
  );
};

export default Me;
