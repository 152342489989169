import HomeIcon from "@mui/icons-material/Home";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const text = `1. Postanowienia ogólne
    1.1. Niniejszy regulamin określa zasady korzystania ze strony https://thls.pl/.
    1.2. Strona jest własnością Piotra TheLoloS Sałaga.
    1.3. Korzystając ze Strony, użytkownik akceptuje i zobowiązuje się do przestrzegania niniejszego regulaminu.

2. Prawa autorskie
    2.1. Wszystkie treści zamieszczone na Stronie, takie jak teksty, grafiki, zdjęcia, filmy itp., są chronione prawem autorskim i stanowią własność Właściciela.
    2.2. Kopiowanie, rozpowszechnianie lub wykorzystywanie jakiejkolwiek treści ze Strony bez zgody Właściciela jest zabronione.

3. Polityka prywatności
    3.1. Strona używa plików cookie. Szczegółowe informacje na temat polityki prywatności i używania plików cookie znajdują się w odrębnym dokumencie "Polityka prywatności".

4. Wtyczka Messenger od Facebook
    4.1. Strona korzysta z wtyczki Messenger dostarczonej przez Facebook Inc.
    4.2. Korzystając z wtyczki Messenger, użytkownik akceptuje i podlega warunkom korzystania z usług Facebooka oraz polityce prywatności Facebooka.
    4.3. Właściciel nie ponosi odpowiedzialności za działanie wtyczki Messenger ani za przekazywane za jej pośrednictwem treści.

5. Odpowiedzialność
    5.1. Właściciel dokłada wszelkich starań, aby treści zamieszczone na Stronie były dokładne i aktualne. Niemniej jednak, Właściciel nie gwarantuje, że treści te są wolne od błędów, kompletności, dokładności lub aktualności.
    5.2. Właściciel nie ponosi odpowiedzialności za szkody wynikłe z korzystania ze Strony lub związane z nią, w tym straty danych, utraty zysków lub uszkodzenia sprzętu.

6. Zmiany w regulaminie
    6.1. Właściciel zastrzega sobie prawo do zmiany niniejszego regulaminu w dowolnym czasie.
    6.2. Zmieniony regulamin będzie publikowany na Stronie i będzie obowiązywał od daty jego opublikowania.
    6.3. Korzystając ze Strony po wprowadzeniu zmian w regulaminie, użytkownik akceptuje nowe postanowienia.

7. Postanowienia końcowe
    7.1. Niniejszy regulamin podlega polskiemu prawu i wszelkie spory wynikłe z jego stosowania będą rozstrzygane przez właściwe sądy.
    7.2. Jeżeli którekolwiek postanowienie niniejszego regulaminu zostanie uznane za nieważne, pozostałe postanowienia nadal będą miały pełną moc prawną.
    7.3. Właściciel zastrzega sobie prawo do zmiany lub usunięcia Strony w dowolnym czasie bez wcześniejszego powiadomienia.
    7.4. Użytkownicy korzystają ze Strony na własne ryzyko. Właściciel nie ponosi odpowiedzialności za szkody wynikłe z korzystania ze Strony lub związane z nią.
    7.5. Wszelkie pytania, uwagi lub zgłoszenia dotyczące niniejszego regulaminu należy kierować do Właściciela za pomocą dostępnych na Stronie formularzy kontaktowych lub informacji kontaktowych.

8. Komunikacja i zgłaszanie problemów
    8.1. Użytkownicy mogą skontaktować się z Właścicielem Strony za pomocą dostępnych na Stronie formularzy kontaktowych lub informacji kontaktowych.
    8.2. W przypadku napotkania problemów z działaniem Strony, użytkownicy są zobowiązani do zgłoszenia tego faktu Właścicielowi w celu rozwiązania problemu.
    
9. Linki zewnętrzne
    9.1. Strona może zawierać linki do zewnętrznych stron internetowych, które są poza kontrolą Właściciela.
    9.2. Właściciel nie ponosi odpowiedzialności za zawartość, dokładność lub dostępność tych zewnętrznych stron.
    9.3. Użytkownicy korzystają z tych zewnętrznych stron na własne ryzyko i zgodnie z warunkami korzystania ustalonymi przez te strony.
    
10. Reklama i promocje
    10.1. Właściciel zastrzega sobie prawo do umieszczania reklam lub promocji na Stronie.
    10.2. Treści reklamowe i promocyjne mogą być dostarczane przez Właściciela lub innych partnerów.
    10.3. Użytkownicy korzystają z takich treści na własne ryzyko, a Właściciel nie ponosi odpowiedzialności za żadne szkody lub straty wynikłe z takiej reklamy lub promocji.
    `;

const Privacy = () => {
  const navigate = useNavigate();
  return (
    <motion.div
      className="relative z-20 flex h-[100dvh] w-full flex-col items-center justify-center"
      initial={{ y: -1000, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 1000, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <p className="mb-8 text-tlg text-mainDark dark:text-neutral-100">
        Regulamin:
      </p>
      <p className="max-h-[75dvh]  w-2/3 max-w-5xl overflow-x-hidden overflow-y-scroll whitespace-pre-wrap text-tmd text-mainDark dark:text-neutral-100">
        {text}
      </p>
    </motion.div>
  );
};

export default Privacy;
