import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";

interface cookiesType {
  CookiesActive: (params: boolean) => void;
}

export default function CookiesContainer(props: cookiesType) {
  const { CookiesActive } = props;
  const navigate = useNavigate();

  return (
    <>
      <motion.div
        initial={{ y: 1000, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
          transition: { delay: 1.4 },
        }}
        transition={{ duration: 0.5 }}
        exit={{ y: 1000, opacity: 0 }}
        className="fixed bottom-0 z-50 
          flex h-auto  w-screen flex-row flex-nowrap items-center bg-neutral-900 bg-opacity-80 p-6 text-left font-consolas text-txs text-white"
      >
        <p className="ml-[5vw] w-2/3">
          <b className="text-tsm">
            Korzystamy z plików cookies, aby świadczyć usługi na najwyższym
            poziomie oraz prezentować Ci dopasowane do Ciebie treści.
          </b>
          <br />
          <br />
          <i className="">
            Klikając <b>„AKCEPTUJĘ”</b> wyrażasz zgodę na wykorzystywanie przez
            nas plików cookies. Więcej informacji o tym, jak wykorzystujemy
            pliki cookies znajdziesz
          </i>
          <a
            onClick={(e) => {
              e.preventDefault();

              navigate("/privacy-policy");
            }}
            href="/privacy-policy"
            className="whitespace-nowrap text-tsm text-indigo-500"
          >
            <b> Tutaj</b>
          </a>
        </p>

        <div className="flex h-full w-1/3 items-center justify-center">
          <motion.button
            whileTap={{ scale: 0.9 }}
            className="relative flex h-12 w-1/3 min-w-[6rem]
                items-center justify-center overflow-hidden rounded-full bg-indigo-600 font-consolas
                 font-bold text-white dark:bg-white      
                 dark:text-mainDark   
                 sm:min-h-[4rem]
                 sm:max-w-[10rem]
                xl:text-tmd 2xl:min-h-[4rem] 
                2xl:max-w-[14rem] 3xl:min-h-[4rem]
                3xl:max-w-[17rem] 4xl:min-h-[5rem] 4xl:max-w-[20rem]"
            onClick={() => {
              localStorage.setItem("Cookies", "true");
              CookiesActive(false);
            }}
          >
            AKCEPTUJĘ
          </motion.button>
        </div>
      </motion.div>
    </>
  );
}
