import TextAnim from "./TextAnim";
import SecondMainText from "./SecondMainText";
import { motion } from "framer-motion";

const Main = () => {
  sessionStorage.setItem("lastSite", "");
  return (
    // <div className="bg-gradient-to-br from-white via-white to-purple dark:bg-mainDark">
    <motion.div
      initial={{ y: -1000 }}
      animate={{ y: 0 }}
      exit={{ y: 1000 }}
      transition={{ duration: 0.5 }}
      className=" absolute flex  h-[100dvh]  w-[calc(100%-3rem)] sm:w-[calc(100%-4rem)] xl:w-[calc(100%-5rem)]"
    >
      <motion.div className="z-30 m-8 mt-[15svh] flex h-[50svh] w-full max-w-3xl flex-col items-center justify-evenly rounded-lg border-2 border-neutral-700 p-8 shadow backdrop-blur-[2px] dark:border-white tall:mt-0 tall:h-[100svh] tall:n:justify-evenly tall:md:justify-evenly lg:max-w-4xl lg:scale-75 lg:items-center lg:justify-center xl:max-w-4xl 2xl:max-w-5xl">
        <TextAnim />
        <SecondMainText />
      </motion.div>

      <motion.div
        variants={{
          start: {
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
            },
          },
          infinity: {
            y: [0, -10, 0],
            transition: {
              duration: 2,
              delay: 0.5,
              repeat: Infinity,
            },
          },
        }}
        animate={["infinity"]}
        className="absolute -bottom-[10svh] z-20  !h-[60svh] w-full overflow-hidden"
      >
        <motion.img
          className=" h-full w-full opacity-100"
          src="Media/undraw_programming_re_kg9v.svg"
          alt="freelancer"
          initial={{ x: "40vw" }}
        />
      </motion.div>
    </motion.div>
  );
};

export default Main;
