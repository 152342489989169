import { useContext, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  animation1,
  animation2,
  transition1,
  transition2,
} from "../../Other/Styles&Animations/MotionObj";
import { LanguageContext } from "../../Other/Language/LanguageProvider";
import * as lang from "../../Other/Language/Language";

function MainButtons() {
  const { language } = useContext(LanguageContext);
  const [isAnimating, setIsAnimating] = useState(true);

  return (
    <motion.div className="mt-6 flex flex-row justify-center tall:justify-start md:justify-center">
      <motion.button
        onMouseEnter={() => setIsAnimating(false)}
        onMouseLeave={() => setIsAnimating(true)}
        className="
        relative flex h-12 w-2/5 min-w-[6rem]
        content-center items-center overflow-hidden rounded-full
      bg-indigo-600 font-consolas text-lg font-bold
      text-white hover:bg-purple dark:bg-neutral-700
      sm:min-h-[4rem] sm:max-w-[10rem] 
      sm:tall:min-h-[3rem] sm:tall:max-w-[6rem]
      2xl:min-h-[5rem] 2xl:max-w-[14rem]
      3xl:min-h-[6rem] 3xl:max-w-[17rem]
      4xl:min-h-[7rem]  4xl:max-w-[20rem]      
        "
        key="animating"
        animate={isAnimating ? animation1 : animation2}
        transition={isAnimating ? transition1 : transition2}
      >
        <Link
          to={"/portfolio"}
          className={
            "flex !h-full !w-full items-center justify-center p-0 text-center text-tmd sm:text-tlg sm:tall:text-tmd md:text-tlg 2xl:text-txl"
          }
        >
          Start
        </Link>
      </motion.button>

      <motion.button
        className="
          relative ml-[3vw] flex h-12 w-2/5 min-w-[6rem]
          content-center items-center overflow-hidden rounded-full 
          border-2
           border-mainDark bg-neutral-100 bg-opacity-90 font-consolas
           font-bold text-mainDark dark:border-white
           dark:bg-mainDark dark:bg-opacity-0 dark:text-white 
           sm:min-h-[4rem] sm:max-w-[10rem]
           sm:border-[3px]  sm:tall:min-h-[3rem]         
           sm:tall:max-w-[6rem]
           2xl:min-h-[5rem] 2xl:max-w-[14rem] 
           3xl:min-h-[6rem] 3xl:max-w-[17rem]
           4xl:min-h-[7rem] 4xl:max-w-[20rem]
           "
        whileHover={{ scale: 1.1 }}
      >
        <Link
          to={"/me"}
          className="flex !h-full !w-full items-center justify-center whitespace-nowrap p-0 text-center text-tmd sm:text-tlg sm:tall:text-tmd md:text-tlg 2xl:text-txl"
        >
          {lang.secondFirstBtn[language]}
        </Link>
      </motion.button>
    </motion.div>
  );
}

export default MainButtons;
