import React, { useState, createContext } from "react";

// * creating a type for the context
type LanguageContextType = {
  language: string;
  changeLanguage: (newLanguage: string) => void;
};

// * create context with language current state
export const LanguageContext = createContext<LanguageContextType>(
  // * add default object (if nothing will be get from props)
  {
    language: "pl",
    changeLanguage: () => {},
  }
);

// * set props type
type Props = {
  children: React.ReactNode;
};

// * create language provider
const LanguageProvider = ({ children }: Props) => {
  // * create state with default pl value. need it for stash language state
  const localStorgadeLanguage = localStorage.getItem("language");

  const [language, setLanguage] = useState<string>(
    localStorgadeLanguage ? localStorgadeLanguage : "pl"
  );

  // * creating a method that will allow you to edit the state of the context (setLanguage)
  const changeLanguage = (newLanguage: string) => {
    document.documentElement.lang = String(newLanguage);
    setLanguage(newLanguage);
  };

  // * Return context element with parametr to use (to be able to use them in children of components)
  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
