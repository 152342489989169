import { motion } from "framer-motion";
import { useContext } from "react";
import AnimatedButton from "./AnimatedButton";
import { LanguageContext } from "../../Other/Language/LanguageProvider";
import * as lang from "../../Other/Language/Language";

const Portfolio = () => {
  const { language } = useContext(LanguageContext);
  sessionStorage.setItem("lastSite", "contact");

  return (
    <motion.div
      initial={{ y: -1000 }}
      animate={{ y: 0 }}
      exit={{ y: 1000 }}
      transition={{ duration: 0.5 }}
      className="absolute flex h-[100dvh] w-[calc(100%-3rem)] flex-row justify-center tall:justify-center sm:w-[calc(100%-4rem)] xl:w-[calc(100%-5rem)] 2xl:w-[calc(100%-6rem)] 3xl:w-[calc(100%-7rem)] 5xl:w-[calc(100%-8rem)]"
    >
      {/* //! Contact */}
      <div className=" z-40 my-auto mt-[15vh] flex w-3/4 max-w-2xl flex-col items-center justify-start rounded-lg border-2 border-neutral-700 p-8 text-center text-mainDark backdrop-blur-[2px] dark:border-white  dark:text-white xl:max-w-4xl">
        <p
          lang="pl"
          className="
              z-40  inline-flex w-full justify-center border-b-2 
              !border-mainDark pb-[3vh]  font-consolas text-t2xl
              font-extrabold uppercase  text-mainDark dark:!border-white dark:text-white
            tall:mt-[4vh] tall:!max-w-[40vw] tall:text-tlg sm:border-b-[3px]
              xl:text-t3xl 2xl:text-t4xl"
        >
          {lang.contactTitle[language]}
        </p>
        <div className="flex w-full flex-row flex-wrap tall:mt-[3vh]">
          {/* //! Messenger */}
          <SocialBtn
            href="https://m.me/108334074173610/"
            textToCopy="https://m.me/108334074173610/"
            secondText="Skopiowano"
            startText="Messager"
            text="m.me/thls"
          />
          {/* //! E-mail */}
          <SocialBtn
            href="mailto:Kontact@thls.pl?subject=Pytanie"
            textToCopy="Kontakt@thls.pl"
            secondText="Skopiowano"
            startText="E-mail"
            text="Kontakt@thls.pl"
          />
          {/* //! Phone */}
          <SocialBtn
            href="tel:+48 576 399 606"
            textToCopy="+48 576 399 606"
            secondText="Skopiowano"
            startText="Telefon"
            text="+48 576399606"
          />
          {/* //! Whatsapp */}
          <SocialBtn
            href="https://wa.me/48576399606"
            textToCopy="https://wa.me/48576399606"
            secondText="Skopiowano"
            startText="Whatsapp"
            text="WA.ME/4857639"
          />
        </div>
      </div>
      {/* //! image */}
      <img
        alt="bookshelf svg"
        src="./Media/MainFreelancer.svg"
        className="absolute bottom-0 left-0 z-10 max-h-[50dvh] w-[100vw] opacity-100 tall:max-h-[40dvh]"
      />
    </motion.div>
  );
};

interface SocialProps {
  href: string;
  // textToCopy: string
  textToCopy: string;
  // startText: string;
  startText: string;
  // secondText: string;
  secondText: string;
  // showed text: string;
  text: string;
}

function SocialBtn(props: SocialProps) {
  const { href, textToCopy, startText, secondText, text } = props;

  return (
    <div className="mt-[3vh] mb-[1vh] flex w-1/2 flex-col items-center justify-between tall:w-1/4 md:w-1/4">
      <div className="flex h-full w-1/2  items-center justify-center">
        <AnimatedButton
          textToCopy={textToCopy}
          startText={startText}
          secondText={secondText}
        />
      </div>
      <a
        className={
          "my-auto mt-[3vh]  whitespace-nowrap text-center font-consolas text-tsm text-mainDark dark:text-white sm:text-tmd xl:text-tlg 2xl:text-tlg 3xl:text-txl"
        }
        href={href}
        rel="noreferrer"
        target="_blank"
        placeholder="wejdź"
      >
        {text}
      </a>
    </div>
  );
}

export default Portfolio;
