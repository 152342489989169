import { ReactNode } from "react";
import { HTMLMotionProps, MotionValue, motion } from "framer-motion";

//! handing over children and giving props, e.g. onClick
interface SvgIcon extends HTMLMotionProps<"button"> {
  children: ReactNode | MotionValue<number> | MotionValue<string>;
}
//! exporting function
export default function Button({ children, ...props }: SvgIcon) {
  return (
    <motion.button
      whileTap={{ scale: 0.9 }}
      className="z-40 h-10 max-h-[10vw] w-10 max-w-[10vw] origin-center rounded-full  bg-neutral-700 text-white  dark:bg-white dark:text-indigo-500 sm:h-10 sm:w-10 xl:h-12 xl:w-12"
      {...props}
    >
      {children}
    </motion.button>
  );
}
