import { motion } from "framer-motion";
import { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../../Other/Language/LanguageProvider";
import { TextSelfTyping } from "../../Other/Language/Language";

const TextAnim = () => {
  const { language } = useContext(LanguageContext);
  const allTexts = TextSelfTyping[language];
  const [spans, setSpans] = useState<string>("");
  const [number, setNumber] = useState<number>(0);

  useEffect(() => {
    let i: number = 0;
    const interval = setInterval(() => {
      if (i === allTexts[number].length) {
        clearInterval(interval);
        number >= allTexts.length - 1
          ? setTimeout(() => {
              setNumber(Number(0));
              setSpans("");
            }, 2000)
          : setTimeout(() => {
              setNumber((prev) => prev + 1);
              setSpans("");
            }, 2000);
      } else {
        i++;
        setSpans((prev) => {
          return prev + allTexts[number][i - 1];
        });
      }
    }, 50);
    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [number]);

  return (
    <motion.div className=" z-40 mx-auto mb-[1.5vh] flex h-[12svh] max-h-[10vw] min-h-[5svh] w-3/4 flex-wrap content-start items-start justify-center tall:h-[20svh] tall:justify-start md:w-3/4 md:justify-center  lg:mx-0 lg:mb-[6vh] 4xl:mb-20">
      {spans.split("").map((e, i) => {
        return (
          <motion.span
            key={i}
            initial={{ opacity: 0 }}
            animate={{ scaleY: [0, 1], y: [-1, 0], opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
            className={
              e === "*"
                ? "z-30 block !h-2 w-full whitespace-pre font-consolas text-tlg font-bold uppercase text-mainDark  dark:text-white tall:text-tlg  lg:text-txl xl:text-t2xl 2xl:text-t3xl 3xl:text-t3xl 4xl:text-t4xl"
                : "z-30 whitespace-pre font-consolas text-tlg font-bold uppercase text-mainDark dark:text-white  lg:text-txl xl:text-t2xl 2xl:text-t3xl 3xl:text-t3xl 4xl:text-t4xl "
            }
          >
            {e === "*" ? " " : e}
          </motion.span>
        );
      })}
      <motion.span
        initial={{ scaleY: 1 }}
        animate={{ opacity: [0, 1] }}
        transition={{
          duration: 0.5,
          repeat: Infinity,
          repeatType: "reverse",
        }}
        className="z-30 whitespace-pre font-consolas text-tlg font-bold uppercase text-mainDark dark:text-white tall:text-tlg  lg:text-txl xl:text-t2xl 2xl:text-t3xl 3xl:text-t3xl 4xl:text-t4xl "
        // "2xl:text-t1xl  z-30 min-w-[0.5rem] whitespace-pre bg-mainDark font-consolas text-tlg font-bold uppercase text-mainDark text-mainDark dark:bg-white dark:text-white dark:text-white tall:text-tlg lg:text-tlg xl:text-txl 3xl:text-t2xl "
        // "bg-mainDark text-mainDark dark:text-white dark:bg-white"
      >
        <p className=" bg-mainDark text-mainDark dark:bg-white dark:text-white">
          {" "}
        </p>
        {/* &U+1F536; */}
      </motion.span>
    </motion.div>
  );
};

export default TextAnim;
