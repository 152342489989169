import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Tooltip } from "@mui/material";
import { TooltipTheme } from "../../../Other/Language/Language";
import { LanguageContext } from "../../../Other/Language/LanguageProvider";

interface Props {
  start: boolean;
}

export default function ThemeButton({ start }: Props) {
  const [theme, setTheme] = useState(start);
  const { language } = useContext(LanguageContext);
  // console.log(TooltipTheme[language][0]);
  function toggleTheme() {
    const status = document.querySelector("html")?.getAttribute("data-mode");
    status === "dark"
      ? document.querySelector("html")?.setAttribute("data-mode", "light")
      : document.querySelector("html")?.setAttribute("data-mode", "dark");
    status === "dark"
      ? localStorage.setItem("theme", "light")
      : localStorage.setItem("theme", "dark");
    setTheme((prev) => !prev);
  }
  useEffect(() => {
    let body = document.body;
    theme ? body.classList.add("dark") : body.classList.remove("dark");
    theme ? body.classList.add("light") : body.classList.remove("light");
  }, [theme]);

  return (
    <Button onClick={(e) => toggleTheme()}>
      <Tooltip
        title={theme ? TooltipTheme[language][0] : TooltipTheme[language][1]}
      >
        {theme ? <LightModeIcon /> : <DarkModeIcon />}
      </Tooltip>
    </Button>
  );
}
