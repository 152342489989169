import { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import { SvgIconComponent } from "@mui/icons-material";
import { motion } from "framer-motion";

interface ModalProps {
  icon: SvgIconComponent;
  title: string;
  text: string;
  buttonText: string;
  type: string;
  secondBtn?: boolean;
  secondBtnText?: string;
  delay?: number;
}

const useModal = () => {
  // ! edit to change default values of modal
  const defaultObject = {
    icon: HelpIcon,
    title: "",
    text: "",
    buttonText: "",
    delay: 0,
    secondBtn: false,
    secondBtnText: "",
    type: "",
    on: false,
  };

  const [constent, setContent] = useState<ModalProps & { on: boolean }>(
    defaultObject
  );

  function OffModal() {
    localStorage.setItem(constent.type, "true");
    setContent(defaultObject);
  }

  // ! set state with object of modal
  const OnModal = ({
    icon,
    title,
    text,
    buttonText,
    secondBtn,
    delay,
    type,
    secondBtnText,
  }: ModalProps) => {
    setContent({
      type: type,
      icon: icon,
      title: title,
      text: text,
      buttonText: buttonText,
      secondBtn: secondBtn ? secondBtn : defaultObject.secondBtn,
      secondBtnText: secondBtnText
        ? secondBtnText
        : defaultObject.secondBtnText,
      delay: delay ? delay : defaultObject.delay,

      // must have this open it
      on: true,
    });
  };

  //   ! edit for edit modal

  function Modal() {
    return (
      <motion.div
        initial={{ opacity: 0, y: 400 }}
        animate={{ opacity: 1, y: 0, transition: { delay: constent.delay } }}
        exit={{ opacity: 0, y: 400 }}
        transition={{ duration: 0.3 }}
        className="excluded-element absolute top-0 left-0 z-[999999999] flex  h-[100dvh] w-screen flex-col  items-center justify-center bg-black bg-opacity-90 dark:bg-black dark:bg-opacity-70"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{
            duration: 0.3,
          }}
          className="rounded-md bg-white bg-opacity-100 p-2  font-consolas dark:bg-neutral-950 dark:bg-opacity-90 n:w-96 n:max-w-[80vw] n:text-tmd sm:text-tmd xl:w-128"
        >
          <div className="ml-6 inline-flex w-40 border-b-2 border-b-mainDark p-4 pl-0 text-tmd font-bold dark:border-b-white sm:text-tlg">
            <p className="text-tlg text-mainDark dark:text-white sm:text-txl">
              {constent.title}
            </p>
            <constent.icon className="ml-4 !fill-purple dark:!fill-white" />
          </div>
          <p className=" m-6 max-h-[25svh] overflow-scroll whitespace-pre-wrap rounded-md   p-4 text-mainDark dark:text-white sm:overflow-auto">
            {constent.text}
          </p>

          <div className=" inline-flex w-full justify-end">
            {constent.secondBtn && (
              <motion.button
                // whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="m-4  block h-12  min-w-[6rem] rounded-full bg-neutral-700  p-2 font-consolas text-tsm font-bold text-white dark:bg-neutral-700  
                    sm:min-h-[3rem] 
                    sm:max-w-[10rem] 2xl:min-h-[3rem]
                    2xl:max-w-[14rem] 3xl:min-h-[3rem]
                    3xl:max-w-[17rem] 4xl:min-h-[3rem] 4xl:max-w-[20rem]"
                onClick={() => {
                  alert("work");
                  localStorage.setItem(constent.type, "true");
                }}
              >
                {constent.secondBtnText}
              </motion.button>
            )}
            <motion.button
              // whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="m-4  block h-12  min-w-[6rem] rounded-full bg-neutral-700  p-2 font-consolas text-tsm font-bold text-white dark:bg-neutral-700  
                  sm:min-h-[3rem] 
                  sm:max-w-[10rem] 2xl:min-h-[3rem]
                  2xl:max-w-[14rem] 3xl:min-h-[3rem]
                  3xl:max-w-[17rem] 4xl:min-h-[3rem] 4xl:max-w-[20rem]"
              onClick={() => {
                OffModal();
                localStorage.setItem(constent.type, "true");
              }}
            >
              {constent.buttonText}
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    );
  }

  return { Modal, OffModal, OnModal, constent };
};

export default useModal;
