import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

const Animation = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="h-screen w-screen absolute -z-50 top-0 left-0">
      <motion.button
        whileTap={{ scale: 1.2 }}
        className="absolute rounded-lg left-[44vw]  bottom-[10vh] bg-blue shadow-xl text-white w-[12vw] h-[8vh] z-[99999] "
        onClick={() => setShow(true)}
      >
        Start
      </motion.button>
      <img
        alt="background"
        src="/Media/bg.jpg"
        className="w-full h-full absolute top-0 left-0 z-10"
      />
      <AnimatePresence>
        {show &&
          [...Array(50).keys()].map((e, i) => (
            <motion.div
              key={i}
              initial={{ height: "0vh", marginBottom: "2vh" }}
              animate={{ height: "2vh", marginBottom: "0vh" }}
              exit={{ height: "0vh", marginBottom: "2vh" }}
              transition={{ duration: 0.5, delay: 50 * 0.01 - (i + 1) * 0.01 }}
              onAnimationComplete={() =>
                setTimeout(() => {
                  setShow(false);
                }, 500)
              }
              className="relative item h-[2vh] bg-black z-50"
            ></motion.div>
          ))}
      </AnimatePresence>
    </div>
  );
};

export default Animation;
