type textObj = {
  [key: string]: string;
};
type allTexts = {
  [key: string]: string[];
};

export const navBar1: textObj = {
  pl: "PIOTR SAŁAGA",
  en: "PIOTR SAŁAGA",
};

export const navBar2: textObj = {
  pl: "O MNIE",
  en: "ABOUT ME",
};
export const navBar3: textObj = {
  pl: "PORTFOLIO",
  en: "PORTFOLIO",
};
export const navBar4: textObj = {
  pl: "KONTAKT",
  en: "CONTACT",
};
export const secondMainText: textObj = {
  pl: "FULL-STACK DEVELOPER",
  en: "FULL-STACK DEVELOPER",
};
export const mainFirstBtn: textObj = {
  pl: "Start",
  en: "Start",
};
export const secondFirstBtn: textObj = {
  pl: "O Mnie",
  en: "About me",
};
export const meTitle: textObj = {
  pl: "O MNIE",
  en: "ABOUT ME",
};
export const meText: textObj = {
  pl: "Nazywam się Piotr Sałaga. Jestem pełnym pasji do programowania młodym człowiekiem, szukającym interesujących projektów do realizacji. Cenię dokładność i chciałbym pokazać swój profesjonalizm w każdym powierzonym mi zadaniu.",
  en: "My name is Piotr Salaga. I am a young man full of passion for programming, looking for interesting projects to implement. I value accuracy and would like to show my professionalism in every task entrusted to me.",
};
export const contactTitle: textObj = {
  pl: "KONTAKT",
  en: "CONTACT",
};
export const contactThirdBtn: textObj = {
  pl: "Numer telefonu: ",
  en: "Phone number: ",
};
export const ModalCanvas: textObj = {
  pl: `Zanurz się we wspaniały świat Reksia, gry z moich najmłodszych lat! 🎲

🔀: Aby sterować: Scroll, Strzałki, PgUp/PgDown, Dotyk, touchpad (experymentalny!).
🗺: Aby nawigować: Kliknij ikonę mapy w prawym górnym rogu.
📱: Jezeli jesteś na urządzeniu mobilnym zalecamy obrócić ekran i włączyć tryb
pełno ekranowy!
`,

  en: `Immerse yourself in the wonderful world of Reksio, a game from my earliest years! Use:

🔀: To move: Scroll, Arrow, Touch, PgUp/Down.
🗺: To navigate: Click map icon in top right corner.

❌: Don't use trackpad it's bugged`,
};
export const ModalTutorial: textObj = {
  pl: `✅: Przed rozpoczęciem przygody kliknij przycisk opcji "⚙" w prawym górnym rogu!
🔀: Aby sterować: Scroll, Strzałki, PgUp/PgDown, Dotyk, touchpad (experymentalny!).
🔋: Zwiększ lub zmniejszy wydajnośc strony poprzez ustawienia wydajności 😘!
`,

  en: `✅: Before starting the adventure, click the settings button "⚙" in the upper right corner!
🔀: To control: Scroll, Arrows, PgUp/PgDown, Touch, touchpad (experimental!).
🔋: Increase or decrease website performance via performance settings 😘!`,
};
export const TextSelfTyping: allTexts = {
  pl: [
    "Hej tu*Piotr TheLoloS Sałaga! ",
    "Potrzebujesz strony? ",
    "Może aplikacji*na komputer? ",
    "Może marzyłeś o grze? ",
    "Lub apce na telefon? ",
    "A może poprostu,*masz pytanie? ",
    "Napisz, pomoge! ✔ ",
  ],
  en: [
    "Hi, I'm*Piotr TheLoloS Sałaga! ",
    "Do you need a page? ",
    "Maybe a desktop app? ",
    "Maybe you*dreamed of a game? ",
    "Or a phone app?",
    "Or maybe you just*have a question? ",
    "Write, I'll help! ✔ ",
  ],
};

export const TooltipLanguage: textObj = {
  pl: "Zmień na angielski",
  en: "Change to polish",
};
export const TooltipTheme: allTexts = {
  pl: ["Tryb ciemny", "Tryb jasny"],
  en: ["Dark mode", "Light mode"],
};
export const TooltipEffency: allTexts = {
  pl: [
    "Tryb podstawowy: minimalne zużycie zasobów",
    "Tryb średni: dodatkowe funkcje",
    "Tryb wydajny: pełna funkcjonalność",
  ],
  en: [
    "Basic mode: minimal resource consumption",
    "Medium mode: additional features",
    "Performance mode: full functionality",
  ],
};
export const TooltipFullscreen: allTexts = {
  pl: ["Zamknij pełny ekran", "Otwórz pełny ekran"],
  en: ["Close fullscreen", "Open fullscreen"],
};
export const TooltipStage1: textObj = {
  pl: "O mnie",
  en: "About me",
};
export const TooltipStage2: textObj = {
  pl: "Znane technologie",
  en: "Known technologies",
};
export const TooltipStage3: textObj = {
  pl: "Reksio",
  en: "Reksio",
};
export const TooltipStage4: textObj = {
  pl: "Moje projekty",
  en: "My projects",
};
export const TooltipStage5: textObj = {
  pl: "Poznawane technologie",
  en: "Learned technologies",
};
export const TooltipMap: textObj = {
  pl: "Nawigacja",
  en: "Navigation",
};
export const TooltipOptions: textObj = {
  pl: "Opcje",
  en: "Options",
};
